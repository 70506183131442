export default {
    name: 'InputFile',
    props: {
        inputclass: {
            type: String,
            default: ''
        },        
        accept: {
            type: String,
            default: '*'
        },
    },
};