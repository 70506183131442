import ToggleButton from './togglebutton/Button.vue';

export default {
    components: {
        'togglebutton': ToggleButton
    },
    props: {
        value: Boolean,
        inputclass: {
            type: String,
            default: ''
        }
    },
    name: 'InputToggle',
    data() {
        return {
        };
    },
    methods: {
    }
};